import {
  getAnalytics,
  logEvent as logFirebaseEvent,
  setUserId as setFirebaseUserId,
} from "firebase/analytics";
import { getApp, getApps, initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

let analytics;
if (typeof window !== "undefined" && firebaseConfig.measurementId) {
  try {
    analytics = getAnalytics(app);
    console.log("Firebase analytics initialized");
  } catch (error) {
    console.error("Error initializing Firebase Analytics", error);
  }
}

// Helper functions
export const setUserId = (userId) => {
  try {
    if (analytics) {
      setFirebaseUserId(analytics, userId);
    } else {
      console.warn("Firebase Analytics is not initialized");
    }
  } catch (error) {
    console.error("Error setting Firebase userId", error);
  }
};

export const logEvent = (event, params) => {
  try {
    if (analytics) {
      logFirebaseEvent(analytics, event, params);
      console.log("Logged Firebase event", { event, params });
    } else {
      console.warn("Firebase Analytics is not initialized");
    }
  } catch (error) {
    console.error("Error logging Firebase event", error);
  }
};

export default app;
