// useS3Upload.ts
import axios from "axios";
import { useCallback } from "react";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

type S3Params = {
  file: File;
  bucket: string;
  prefix?: string;
  [key: string]: any;
};

type S3Upload = (
  params: S3Params,
  handleProgress: (progress: { loaded: number; total: number }) => void
) => Promise<{ key: string; url: string }>;

export function ensureUrlEncoded(filename: string): string {
  if (!filename) return "";
  // decode, then re-encode
  const decoded = decodeURIComponent(filename);
  const reEncoded = encodeURIComponent(decoded);

  // if re-encoded matches original, it means original was already properly encoded
  // otherwise, we return the newly encoded version
  return reEncoded === filename ? filename : reEncoded;
}

export const useS3Upload = (): { upload: S3Upload } => {
  const upload: S3Upload = useCallback(async (params, handleProgress) => {
    const { file, prefix = "", bucket } = params;
    const uniqueFileName = `${uuidv4()}-${slugify(file.name, { lower: true })}`;

    // Step 1: Request pre-signed URL from the server
    const response = await axios.post("/api/authenticated/get-presigned-url", {
      fileName: uniqueFileName,
      fileType: file.type,
      prefix,
      bucket,
    });

    if (response.status !== 200) {
      throw new Error("Failed to get pre-signed URL");
    }

    const { signedUrl, key, url } = response.data;

    console.log({ key, url });

    // Step 2: Upload the file directly to S3 using the pre-signed URL
    await axios.put(signedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        handleProgress({
          loaded: progressEvent.loaded,
          total: progressEvent.total || file.size,
        });
      },
    });

    return { key, url };
  }, []);

  return { upload };
};
