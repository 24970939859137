import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import useRequest, { fetcher } from "./useRequest";

export const pauseWorkoutRevalidationKey = (workoutID: string) => [
  "pauseWorkoutRevalidation",
  workoutID,
];

export const usePauseWorkoutRevalidation = (workoutID: string) => {
  // Fetcher function returns the current pause state or false by default
  const fetcher = () => false;

  const { data: isPaused } = useSWR(
    workoutID ? pauseWorkoutRevalidationKey(workoutID) : null,
    fetcher
  );

  /**
   * Function to set the pause state.
   * @param pause Boolean indicating whether to pause revalidation.
   */
  const setPaused = (pause: boolean) => {
    if (!workoutID) return;
    mutate(pauseWorkoutRevalidationKey(workoutID), pause, false);
  };

  return { isPaused: isPaused ?? false, setPaused };
};

export const useWorkoutByID = (workoutID, config = {}) => {
  const { isPaused } = usePauseWorkoutRevalidation(workoutID);

  const swrConfig = {
    ...config,
    isPaused: () => isPaused,
  };

  const response = useRequest(workoutID && `workouts/${workoutID}`, swrConfig);
  return response;
};

export const useWorkout = (config = {}) => {
  const { query } = useRouter();
  const [errors, setErrorsState] = useState({});
  const { data, error, mutate, revalidate, isValidating } = useWorkoutByID(
    query.workoutId,
    config
  );

  const deleteWorkout = async () => {
    try {
      await fetcher.delete(`workouts/${query.workoutId}`);
    } catch (error) {
      setErrorsState((c) => ({
        ...c,
        [query.workoutId as string]: "Something went wrong",
      }));
      Sentry.captureException(error);
    }
  };

  const sectionState = data?.sectionState;

  const coachRecommendations = useMemo<
    {
      replacementPairs: {
        childMovementIDs: string[];
        parentMovementIDs: string[];
      }[];
    }[]
  >(() => {
    if (!data?.movementAlts) return [];
    return data.movementAlts.filter(
      (alt) => alt.usecase === "coachRecommendation"
    );
  }, [data]);

  return {
    sectionState,
    coachRecommendations,
    deleteWorkout,
    data,
    error,
    errors,
    mutate,
    revalidate,
    isValidating: (!data || data?.id !== query.workoutId) && isValidating,
    isLoading: (!data || data?.id !== query.workoutId) && isValidating,
  };
};
