import * as Sentry from "@sentry/nextjs";
import { LoadingOverlay } from "apollo/components/LoadingOverlay";
import { FontProvider } from "apollo/components/font-provider";
import { LayoutDefault } from "apollo/components/layout";
import Root from "apollo/containers/root";
import { setUserId } from "apollo/firebase";
import "apollo/styles/chat.css";
import "apollo/styles/globals.css";
import "emoji-mart/css/emoji-mart.css";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useCurrentUser } from "queries/current-user";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useMountedState, useToggle } from "react-use";
import "resize-observer-polyfill";
import "tippy.js/dist/tippy.css";
import Cross from "ui/components/icons/Cross";
import { ThemeCustomizer } from "ui/components/theme-customizer";
import { ThemeModeProvider } from "ui/components/theme-mode-provider";
import { ThemeSwitcher } from "ui/components/theme-switcher";
import { useSession } from "ui/containers/session";
import { useClearAWSCreds } from "ui/hooks/useClearAWSCreds";
import { useDebounce } from "ui/lib/hooks";
import { identify } from "ui/lib/identify";
import { renderSegmentSnippet } from "ui/lib/renderSegmentSnippet";
import { useBrowserType } from "ui/lib/useBrowserType";

const TopProgressBar = dynamic(
  () => {
    return import("apollo/components/progress");
  },
  { ssr: false }
);

const RootContent = React.memo(({ Component, pageProps, err }) => {
  const { currentUser } = useCurrentUser({
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });
  const { Layout = LayoutDefault, ...layoutProps } = Component;
  const router = useRouter();
  const { query, isReady } = router;
  const { setSession, session } = useSession();
  const [routeChangeActive, setRouteChangeActive] = useState(false);
  const debouncedRouteChangeActive = useDebounce(routeChangeActive, 750);

  useClearAWSCreds();

  useEffect(() => {
    if (!currentUser?.id) return;
    if (!session) return;
    // identify user for sentry
    Sentry.setUser({
      session,
      ...currentUser,
    });

    // identify user for firebase
    setUserId(currentUser.id);

    // identify user for segment
    identify({
      userId: currentUser.id,
    });
  }, [currentUser, session]);

  useEffect(() => {
    if (isReady && query.session) {
      setSession(query.session);
    }
  }, [query, isReady]);

  useEffect(() => {
    const handleRouteChangeStart = () => setRouteChangeActive(true);
    const handleRouteChangeEnd = () => setRouteChangeActive(false);

    // const handleRouteChange = () => {
    //   handleRouteChangeEnd();
    //   window?.analytics?.page?.();
    //   window?.scrollTo?.(0, 0);
    // };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeEnd);
    router.events.on("routeChangeError", handleRouteChangeEnd);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeEnd);
      router.events.off("routeChangeError", handleRouteChangeEnd);
    };
  }, [router.events, router]);

  return (
    <Layout key={Layout.name + router.pathname} {...layoutProps}>
      {debouncedRouteChangeActive ? <LoadingOverlay /> : null}
      <Component {...pageProps} err={err} />
    </Layout>
  );
});

const BrowserWarning = React.memo(() => {
  const [hasMounted, setHasMounted] = React.useState(false);
  const { isChrome, isFirefox, isBrave } = useBrowserType();
  const [closed, toggleClosed] = useToggle(false);
  const mounted = useMountedState();

  useEffect(() => {
    if (!mounted()) return;
    let timeout = setTimeout(() => setHasMounted(true), 2500);

    return () => clearTimeout(timeout);
  }, [mounted]);

  if (!hasMounted) return null;

  if (closed) {
    return (
      <div
        style={{ zIndex: 999 }}
        className="fixed top-0 right-0 mt-3 mr-48 flex-center"
      >
        <button
          onClick={toggleClosed}
          className="p-2 bg-red-100 rounded-full shadow-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-6 text-error"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  }

  if ([isBrave, isChrome, isFirefox].includes(true)) return null;

  return (
    <div
      style={{ zIndex: 999 }}
      className="fixed inset-0 z-50 hidden flex-center md:flex"
    >
      <div className="modal-overlay-dark" onClick={toggleClosed} />
      <div className="relative z-50 flex-col flex-grow max-w-xl px-8 py-6 text-center bg-background text-foreground rounded flex-center">
        <button
          className="absolute top-0 right-0 mt-4 mr-4"
          onClick={toggleClosed}
        >
          <Cross className="text-muted-foreground w-7" />
        </button>
        <div className="space-y-4">
          <h4 className="text-xl font-bold">
            Apollo is not set up to work on this browser
          </h4>
          <p>Functionality may not work as expected.</p>
          <a
            href="https://www.google.com/chrome/"
            className="block text-royal text-md"
          >
            Please download and use Chrome on desktop
          </a>
        </div>
      </div>
    </div>
  );
});

function App(props) {
  const isDev = process.env.NEXT_PUBLIC_ENVIRONMENT !== "prod";
  const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === "prod";
  const favIcon = isDev ? "/favicon-dev.ico" : "/favicon.png";
  const title = `Apollo | ${isDev ? "Dev" : "Prod"}`;

  return (
    <FontProvider>
      <Root>
        <ThemeModeProvider attribute="class" defaultTheme="dark" enableSystem>
          <TopProgressBar />
          <Head>
            <title>{title}</title>
            <link rel="icon" href={favIcon} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta charSet="utf-8" />
            <meta name="theme-color" content="#e7ff00" />
          </Head>
          {process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY && isProd ? (
            <Script
              id="segment"
              dangerouslySetInnerHTML={{
                __html: renderSegmentSnippet(
                  process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
                ),
              }}
            />
          ) : null}
          <BrowserWarning />
          <div id="approot">
            <RootContent {...props} />
          </div>
          <ThemeCustomizer />
          <ThemeSwitcher />
        </ThemeModeProvider>
      </Root>
    </FontProvider>
  );
}

export default App;
